// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-SideBarLayout-module__scrollBar-EPDm8 {
  scrollbar-gutter: stable both-edges;
  background-color: var(--chakra-colors-backgroundSecondary);
  scrollbar-color: var(--chakra-colors-borderPrimary) var(--chakra-colors-backgroundSecondary);
  background: var(--chakra-colors-backgroundSecondary);
}

.nightbot-SideBarLayout-module__sideBar-Uf8yH {
  width: 300px;
}

@media (max-width: 768px) {
  .nightbot-SideBarLayout-module__sideBar-Uf8yH {
    display: none;
  }
}

@media (min-width: 768px) {
  .nightbot-SideBarLayout-module__mobileNavbar-X9tcY {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/layouts/SideBarLayout.module.scss"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,0DAA0D;EAC1D,4FAA4F;EAC5F,oDAAoD;AACtD;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".scrollBar {\n  scrollbar-gutter: stable both-edges;\n  background-color: var(--chakra-colors-backgroundSecondary);\n  scrollbar-color: var(--chakra-colors-borderPrimary) var(--chakra-colors-backgroundSecondary);\n  background: var(--chakra-colors-backgroundSecondary);\n}\n\n.sideBar {\n  width: 300px;\n}\n\n@media (max-width: $mantine-breakpoint-sm) {\n  .sideBar {\n    display: none;\n  }\n}\n\n@media (min-width: $mantine-breakpoint-sm) {\n  .mobileNavbar {\n    display: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scrollBar": `nightbot-SideBarLayout-module__scrollBar-EPDm8`,
	"sideBar": `nightbot-SideBarLayout-module__sideBar-Uf8yH`,
	"mobileNavbar": `nightbot-SideBarLayout-module__mobileNavbar-X9tcY`
};
export default ___CSS_LOADER_EXPORT___;
