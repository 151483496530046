// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-TwoFactorRequiredModal-module__root-ZWfE_ {
  margin-left: auto;
  margin-right: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/twofactor/TwoFactorRequiredModal.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".root {\n  margin-left: auto;\n  margin-right: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `nightbot-TwoFactorRequiredModal-module__root-ZWfE_`
};
export default ___CSS_LOADER_EXPORT___;
