// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-ScrollShadows-module__scrollShadows-lWL0E {
  background:
    linear-gradient(var(--chakra-colors-backgroundPrimary), transparent) center top,
    linear-gradient(transparent, var(--chakra-colors-backgroundPrimary) 70%) center bottom,
    radial-gradient(farthest-side at 50% 0, var(--chakra-colors-blackAlpha-300), rgba(0, 0, 0, 0)) center top,
    radial-gradient(farthest-side at 50% 100%, var(--chakra-colors-blackAlpha-300), rgba(0, 0, 0, 0)) center bottom;

  background-repeat: no-repeat;
  background-size:
    100% 200px,
    100% 200px,
    100% 10px,
    100% 10px;
  background-attachment: local, local, scroll, scroll;
}

.nightbot-ScrollShadows-module__scrollShadows-lWL0E::-webkit-scrollbar {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/ScrollShadows.module.scss"],"names":[],"mappings":"AAAA;EACE;;;;mHAIiH;;EAEjH,4BAA4B;EAC5B;;;;aAIW;EACX,mDAAmD;AACrD;;AAEA;EACE,aAAa;AACf","sourcesContent":[".scrollShadows {\n  background:\n    linear-gradient(var(--chakra-colors-backgroundPrimary), transparent) center top,\n    linear-gradient(transparent, var(--chakra-colors-backgroundPrimary) 70%) center bottom,\n    radial-gradient(farthest-side at 50% 0, var(--chakra-colors-blackAlpha-300), rgba(0, 0, 0, 0)) center top,\n    radial-gradient(farthest-side at 50% 100%, var(--chakra-colors-blackAlpha-300), rgba(0, 0, 0, 0)) center bottom;\n\n  background-repeat: no-repeat;\n  background-size:\n    100% 200px,\n    100% 200px,\n    100% 10px,\n    100% 10px;\n  background-attachment: local, local, scroll, scroll;\n}\n\n.scrollShadows::-webkit-scrollbar {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scrollShadows": `nightbot-ScrollShadows-module__scrollShadows-lWL0E`
};
export default ___CSS_LOADER_EXPORT___;
