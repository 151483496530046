// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-StatusMenu-module__statusIcon-hDU4R {
  color: var(--color-success);
}

.nightbot-StatusMenu-module__dropdown-suif8 {
  min-width: 200px;
}
`, "",{"version":3,"sources":["webpack://./src/components/dashboard/StatusMenu.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAA2B;AAC7B;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".statusIcon {\n  color: var(--color-success);\n}\n\n.dropdown {\n  min-width: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statusIcon": `nightbot-StatusMenu-module__statusIcon-hDU4R`,
	"dropdown": `nightbot-StatusMenu-module__dropdown-suif8`
};
export default ___CSS_LOADER_EXPORT___;
