/* eslint-disable @typescript-eslint/no-explicit-any */ function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
import { AuthProviders as _AuthProviders, UserLevels as _UserLevels, ShortProviderSlugs, LongProviderSlugs, SongRequestProviders, SongRequestPlaylists } from '../../../common/db/constants';
export var UserLevels;
(function(UserLevels) {
    UserLevels[UserLevels["EVERYONE"] = _UserLevels.everyone] = "EVERYONE";
    UserLevels[UserLevels["REGULAR"] = _UserLevels.regular] = "REGULAR";
    UserLevels[UserLevels["SUBSCRIBER"] = _UserLevels.subscriber] = "SUBSCRIBER";
    UserLevels[UserLevels["MODERATOR"] = _UserLevels.moderator] = "MODERATOR";
    UserLevels[UserLevels["TWITCH_VIP"] = _UserLevels.twitch_vip] = "TWITCH_VIP";
    UserLevels[UserLevels["SOOP_VIP"] = _UserLevels.soop_vip] = "SOOP_VIP";
    UserLevels[UserLevels["ADMIN"] = _UserLevels.admin] = "ADMIN";
    UserLevels[UserLevels["OWNER"] = _UserLevels.owner] = "OWNER";
})(UserLevels || (UserLevels = {}));
export var AuthProviders;
(function(AuthProviders) {
    AuthProviders[AuthProviders["TWITCH"] = _AuthProviders.twitch] = "TWITCH";
    AuthProviders[AuthProviders["YOUTUBE"] = _AuthProviders.youtube] = "YOUTUBE";
    AuthProviders[AuthProviders["TROVO"] = _AuthProviders.trovo] = "TROVO";
    AuthProviders[AuthProviders["NOICE"] = _AuthProviders.noice] = "NOICE";
    AuthProviders[AuthProviders["SOOP"] = _AuthProviders.soop] = "SOOP";
})(AuthProviders || (AuthProviders = {}));
var _obj;
export var SlugByAuthProvider = (_obj = {}, _define_property(_obj, ShortProviderSlugs.twitch, AuthProviders.TWITCH), _define_property(_obj, ShortProviderSlugs.youtube, AuthProviders.YOUTUBE), _define_property(_obj, ShortProviderSlugs.trovo, AuthProviders.TROVO), _define_property(_obj, ShortProviderSlugs.noice, AuthProviders.NOICE), _define_property(_obj, ShortProviderSlugs.soop, AuthProviders.SOOP), _define_property(_obj, LongProviderSlugs.t, AuthProviders.TWITCH), _define_property(_obj, LongProviderSlugs.y, AuthProviders.YOUTUBE), _obj);
export var TimeoutReasons;
(function(TimeoutReasons) {
    TimeoutReasons["CAPS"] = "caps";
    TimeoutReasons["EMOTES"] = "emotes";
    TimeoutReasons["LINKS"] = "links";
    TimeoutReasons["SPAM"] = "spam";
    TimeoutReasons["SYMBOLS"] = "symbols";
    TimeoutReasons["BLACKLIST"] = "blacklist";
})(TimeoutReasons || (TimeoutReasons = {}));
export var SpamProtectionFilterTypes;
(function(SpamProtectionFilterTypes) {
    SpamProtectionFilterTypes["BLACKLIST"] = "blacklist";
    SpamProtectionFilterTypes["CAPS"] = "caps";
    SpamProtectionFilterTypes["EMOTES"] = "emotes";
    SpamProtectionFilterTypes["LINKS"] = "links";
    SpamProtectionFilterTypes["SYMBOLS"] = "symbols";
    SpamProtectionFilterTypes["REPETITIONS"] = "repetitions";
})(SpamProtectionFilterTypes || (SpamProtectionFilterTypes = {}));
export var SongRequestPlaylist;
(function(SongRequestPlaylist) {
    SongRequestPlaylist[SongRequestPlaylist["CHANNEL"] = SongRequestPlaylists.channel] = "CHANNEL";
    SongRequestPlaylist[SongRequestPlaylist["MONSTERCAT"] = SongRequestPlaylists.monstercat] = "MONSTERCAT";
})(SongRequestPlaylist || (SongRequestPlaylist = {}));
export var SongRequestProvider;
(function(SongRequestProvider) {
    SongRequestProvider[SongRequestProvider["YOUTUBE"] = SongRequestProviders.youtube] = "YOUTUBE";
    SongRequestProvider[SongRequestProvider["SOUNDCLOUD"] = SongRequestProviders.soundcloud] = "SOUNDCLOUD";
})(SongRequestProvider || (SongRequestProvider = {}));
