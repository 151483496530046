// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-TopChatter-module__pagination-xSB3t {
  padding: 8px;
  gap: 8px;
  display: flex;
  justify-content: right;
}

.nightbot-TopChatter-module__hidden-H1RN2 {
  visibility: hidden;
}

.nightbot-TopChatter-module__chatterColumn-cXXVe {
  text-align: right;
}
`, "",{"version":3,"sources":["webpack://./src/components/dashboard/TopChatter.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,QAAQ;EACR,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".pagination {\n  padding: 8px;\n  gap: 8px;\n  display: flex;\n  justify-content: right;\n}\n\n.hidden {\n  visibility: hidden;\n}\n\n.chatterColumn {\n  text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination": `nightbot-TopChatter-module__pagination-xSB3t`,
	"hidden": `nightbot-TopChatter-module__hidden-H1RN2`,
	"chatterColumn": `nightbot-TopChatter-module__chatterColumn-cXXVe`
};
export default ___CSS_LOADER_EXPORT___;
