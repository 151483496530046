// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-CommandsPublic-module__commandNameColumn-Myrhi {
  min-width: 100px;
}

.nightbot-CommandsPublic-module__commandMessageColumn-p3Av0 {
  width: 100%;
  white-space: normal;
}

.nightbot-CommandsPublic-module__commandUserLevelColumn-O_nHC {
  text-align: right;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/CommandsPublic.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".commandNameColumn {\n  min-width: 100px;\n}\n\n.commandMessageColumn {\n  width: 100%;\n  white-space: normal;\n}\n\n.commandUserLevelColumn {\n  text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"commandNameColumn": `nightbot-CommandsPublic-module__commandNameColumn-Myrhi`,
	"commandMessageColumn": `nightbot-CommandsPublic-module__commandMessageColumn-p3Av0`,
	"commandUserLevelColumn": `nightbot-CommandsPublic-module__commandUserLevelColumn-O_nHC`
};
export default ___CSS_LOADER_EXPORT___;
