// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-input-module__input-Dc9Op {
  background-color: var(--background-primary);
  border-color: var(--background-primary);
  border-width: 2px;

  border-radius: var(--mantine-radius-md);
  font-size: var(--mantine-font-size-md);
}

.nightbot-input-module__input-Dc9Op[data-error] {
    border-color: var(--text-error);
  }

.nightbot-input-module__input-Dc9Op:disabled {
    background-color: var(--background-primary);
  }

.nightbot-input-module__input-Dc9Op::-moz-placeholder {
    font-weight: 500;
  }

.nightbot-input-module__input-Dc9Op::placeholder {
    font-weight: 500;
  }

.nightbot-input-module__input-Dc9Op[data-error]::-csstools-invalid-placeholder {
      color: var(--text-error);
    }

.nightbot-input-module__input-Dc9Op[data-variant='outline'] {
    border: 1px solid var(--border-secondary);
    background-color: var(--background-secondary);
  }

.nightbot-input-module__input-Dc9Op[data-variant='outline'][data-error] {
      border-width: 2px;
      border-color: var(--text-error);
    }

.nightbot-input-module__textInput-xSksR {
  min-height: 40px;
  padding: 0px 16px;
}

.nightbot-input-module__textarea-NruVc {
  padding: 8px 16px;
  min-height: 80px;
}

.nightbot-input-module__inputWrapperRoot-MopDM label {
  font-size: var(--mantine-font-size-md);
}

.nightbot-input-module__inputWrapperRoot-MopDM:has(.nightbot-input-module__inputWrapperLabel-ONvTH, .nightbot-input-module__inputWrapperDescription-Ue86e) > div:has(input, textarea, select) {
  margin-top: 8px;
}

.nightbot-input-module__switchTrack-waQyH {
  min-width: 32px;
  width: 32px;
}

.nightbot-input-module__switchThumb-IOItu {
  --switch-thumb-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/utils/mantine-theme/input.module.scss"],"names":[],"mappings":"AAAA;EACE,2CAA2C;EAC3C,uCAAuC;EACvC,iBAAiB;;EAEjB,uCAAuC;EACvC,sCAAsC;AA2BxC;;AAzBE;IACE,+BAA+B;EACjC;;AAEA;IACE,2CAA2C;EAC7C;;AAEA;IACE,gBAAgB;EAKlB;;AANA;IACE,gBAAgB;EAKlB;;AAHE;MACE,wBAAwB;IAC1B;;AAGF;IACE,yCAAyC;IACzC,6CAA6C;EAM/C;;AAJE;MACE,iBAAiB;MACjB,+BAA+B;IACjC;;AAIJ;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".input {\n  background-color: var(--background-primary);\n  border-color: var(--background-primary);\n  border-width: 2px;\n\n  border-radius: var(--mantine-radius-md);\n  font-size: var(--mantine-font-size-md);\n\n  &[data-error] {\n    border-color: var(--text-error);\n  }\n\n  &:disabled {\n    background-color: var(--background-primary);\n  }\n\n  &::placeholder {\n    font-weight: 500;\n\n    &[data-error] {\n      color: var(--text-error);\n    }\n  }\n\n  &[data-variant='outline'] {\n    border: 1px solid var(--border-secondary);\n    background-color: var(--background-secondary);\n\n    &[data-error] {\n      border-width: 2px;\n      border-color: var(--text-error);\n    }\n  }\n}\n\n.textInput {\n  min-height: 40px;\n  padding: 0px 16px;\n}\n\n.textarea {\n  padding: 8px 16px;\n  min-height: 80px;\n}\n\n.inputWrapperRoot label {\n  font-size: var(--mantine-font-size-md);\n}\n\n.inputWrapperRoot:has(.inputWrapperLabel, .inputWrapperDescription) > div:has(input, textarea, select) {\n  margin-top: 8px;\n}\n\n.switchTrack {\n  min-width: 32px;\n  width: 32px;\n}\n\n.switchThumb {\n  --switch-thumb-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `nightbot-input-module__input-Dc9Op`,
	"textInput": `nightbot-input-module__textInput-xSksR`,
	"textarea": `nightbot-input-module__textarea-NruVc`,
	"inputWrapperRoot": `nightbot-input-module__inputWrapperRoot-MopDM`,
	"inputWrapperLabel": `nightbot-input-module__inputWrapperLabel-ONvTH`,
	"inputWrapperDescription": `nightbot-input-module__inputWrapperDescription-Ue86e`,
	"switchTrack": `nightbot-input-module__switchTrack-waQyH`,
	"switchThumb": `nightbot-input-module__switchThumb-IOItu`
};
export default ___CSS_LOADER_EXPORT___;
