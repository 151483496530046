// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-TargetDropdown-module__targetOverlay-g3lbE {
  position: absolute;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/TargetDropdown.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,yBAAiB;KAAjB,sBAAiB;UAAjB,iBAAiB;EACjB,oBAAoB;AACtB","sourcesContent":[".targetOverlay {\n  position: absolute;\n  user-select: none;\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"targetOverlay": `nightbot-TargetDropdown-module__targetOverlay-g3lbE`
};
export default ___CSS_LOADER_EXPORT___;
