// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-UserMenuButton-module__button-xf4h7 {
  --button-justify: left;
}

.nightbot-UserMenuButton-module__label-U02wx {
  flex: 1;
}

.nightbot-UserMenuButton-module__userStack-NMWUf {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 0px;
}

.nightbot-UserMenuButton-module__userStack-NMWUf .nightbot-UserMenuButton-module__displayName-MnXi8 {
    line-height: 1.2;
    max-width: 100%;
  }

.nightbot-UserMenuButton-module__userStack-NMWUf .nightbot-UserMenuButton-module__viewingAs-Oopmk {
    --text-color: var(--text-secondary);
    line-height: 1.2;
    max-width: 100%;
    margin-bottom: 2px;
  }`, "",{"version":3,"sources":["webpack://./src/components/UserMenuButton.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,cAAc;AAahB;;AAXE;IACE,gBAAgB;IAChB,eAAe;EACjB;;AAEA;IACE,mCAAmC;IACnC,gBAAgB;IAChB,eAAe;IACf,kBAAkB;EACpB","sourcesContent":[".button {\n  --button-justify: left;\n}\n\n.label {\n  flex: 1;\n}\n\n.userStack {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  min-width: 0px;\n\n  .displayName {\n    line-height: 1.2;\n    max-width: 100%;\n  }\n\n  .viewingAs {\n    --text-color: var(--text-secondary);\n    line-height: 1.2;\n    max-width: 100%;\n    margin-bottom: 2px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `nightbot-UserMenuButton-module__button-xf4h7`,
	"label": `nightbot-UserMenuButton-module__label-U02wx`,
	"userStack": `nightbot-UserMenuButton-module__userStack-NMWUf`,
	"displayName": `nightbot-UserMenuButton-module__displayName-MnXi8`,
	"viewingAs": `nightbot-UserMenuButton-module__viewingAs-Oopmk`
};
export default ___CSS_LOADER_EXPORT___;
