// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-modal-module__body-kjsaa {
  padding: 0px;
}
  
.nightbot-modal-module__header-nSjif {
  padding: 20px;
}`, "",{"version":3,"sources":["webpack://./src/utils/mantine-theme/modal.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;AACf","sourcesContent":[".body {\n  padding: 0px;\n}\n  \n.header {\n  padding: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `nightbot-modal-module__body-kjsaa`,
	"header": `nightbot-modal-module__header-nSjif`
};
export default ___CSS_LOADER_EXPORT___;
