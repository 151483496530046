import { useEffect } from 'react';
import { useNavigate, useOutlet } from 'react-router';
import useAuthenticationStore from '@/stores/AuthenticationStore';
import useHydrated from '@/hooks/useHydrated';
import useLoginRedirect from '@/hooks/useLoginRedirect';
import { useShallow } from 'zustand/react/shallow';
export default function AuthenticationRequiredLayout(param) {
    var children = param.children;
    var navigate = useNavigate();
    var _useAuthenticationStore = useAuthenticationStore(useShallow(function(state) {
        return {
            isLoggedIn: state.isLoggedIn,
            accessToken: state.accessToken,
            user: state.user
        };
    })), isLoggedIn = _useAuthenticationStore.isLoggedIn, accessToken = _useAuthenticationStore.accessToken, user = _useAuthenticationStore.user;
    var isHydrated = useHydrated(useAuthenticationStore);
    var outlet = useOutlet();
    var redirectBack = useLoginRedirect();
    useEffect(function() {
        if (isLoggedIn || accessToken != null || user != null || !isHydrated) {
            return;
        }
        redirectBack();
    }, [
        isLoggedIn,
        accessToken,
        navigate,
        user,
        isHydrated,
        redirectBack
    ]);
    if (!isLoggedIn || accessToken == null || user == null || !isHydrated) {
        return null;
    }
    return children !== null && children !== void 0 ? children : outlet;
}
