// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  .nightbot-select-module__select-NTMhr .nightbot-select-module__input-j6X3x {
    --input-line-height: 1.5;
  }
.nightbot-select-module__select-NTMhr[data-size='xs'] .nightbot-select-module__input-j6X3x {
      font-size: var(--mantine-font-size-sm);
    }`, "",{"version":3,"sources":["webpack://./src/utils/mantine-theme/select.module.scss"],"names":[],"mappings":";EACE;IACE,wBAAwB;EAC1B;AAGE;MACE,sCAAsC;IACxC","sourcesContent":[".select {\n  .input {\n    --input-line-height: 1.5;\n  }\n\n  &[data-size='xs'] {\n    .input {\n      font-size: var(--mantine-font-size-sm);\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `nightbot-select-module__select-NTMhr`,
	"input": `nightbot-select-module__input-j6X3x`
};
export default ___CSS_LOADER_EXPORT___;
