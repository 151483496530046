// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-Icon-module__extraSmallIcon-dQqmb {
  width: 8px;
  height: 8px;
}

.nightbot-Icon-module__smallIcon-_UDS8 {
  width: 12px;
  height: 12px;
}

.nightbot-Icon-module__mediumIcon-nnzmI {
  width: 16px;
  height: 16px;
}

.nightbot-Icon-module__largeIcon-cTwj_ {
  width: 32px;
  height: 32px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Icon.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".extraSmallIcon {\n  width: 8px;\n  height: 8px;\n}\n\n.smallIcon {\n  width: 12px;\n  height: 12px;\n}\n\n.mediumIcon {\n  width: 16px;\n  height: 16px;\n}\n\n.largeIcon {\n  width: 32px;\n  height: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"extraSmallIcon": `nightbot-Icon-module__extraSmallIcon-dQqmb`,
	"smallIcon": `nightbot-Icon-module__smallIcon-_UDS8`,
	"mediumIcon": `nightbot-Icon-module__mediumIcon-nnzmI`,
	"largeIcon": `nightbot-Icon-module__largeIcon-cTwj_`
};
export default ___CSS_LOADER_EXPORT___;
