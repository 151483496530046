// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-LogoText-module__logoText-G30Cc {
  display: flex;
  align-items: center;
  color: var(--text-primary);
}

.nightbot-LogoText-module__small-cIxIz {
  gap: 8px;
}

.nightbot-LogoText-module__small-cIxIz .nightbot-LogoText-module__logo-jLyzq {
    width: 20px;
    height: 20px;
  }

.nightbot-LogoText-module__small-cIxIz .nightbot-LogoText-module__title-fp3Cy {
    font-size: var(--mantine-font-size-md);
  }

.nightbot-LogoText-module__medium-QRkn1 {
  gap: 12px;
}

.nightbot-LogoText-module__medium-QRkn1 .nightbot-LogoText-module__logo-jLyzq {
    width: 24px;
    height: 24px;
  }

.nightbot-LogoText-module__medium-QRkn1 .nightbot-LogoText-module__title-fp3Cy {
    font-size: var(--mantine-font-size-lg);
  }

.nightbot-LogoText-module__large-F3crf {
  gap: 16px;
}

.nightbot-LogoText-module__large-F3crf .nightbot-LogoText-module__logo-jLyzq {
    width: 40px;
    height: 40px;
  }

.nightbot-LogoText-module__large-F3crf .nightbot-LogoText-module__title-fp3Cy {
    font-size: var(--mantine-font-size-xl);
  }`, "",{"version":3,"sources":["webpack://./src/components/LogoText.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,0BAA0B;AAC5B;;AAEA;EACE,QAAQ;AAUV;;AARE;IACE,WAAW;IACX,YAAY;EACd;;AAEA;IACE,sCAAsC;EACxC;;AAGF;EACE,SAAS;AAUX;;AARE;IACE,WAAW;IACX,YAAY;EACd;;AAEA;IACE,sCAAsC;EACxC;;AAGF;EACE,SAAS;AAUX;;AARE;IACE,WAAW;IACX,YAAY;EACd;;AAEA;IACE,sCAAsC;EACxC","sourcesContent":[".logoText {\n  display: flex;\n  align-items: center;\n  color: var(--text-primary);\n}\n\n.small {\n  gap: 8px;\n\n  .logo {\n    width: 20px;\n    height: 20px;\n  }\n\n  .title {\n    font-size: var(--mantine-font-size-md);\n  }\n}\n\n.medium {\n  gap: 12px;\n\n  .logo {\n    width: 24px;\n    height: 24px;\n  }\n\n  .title {\n    font-size: var(--mantine-font-size-lg);\n  }\n}\n\n.large {\n  gap: 16px;\n\n  .logo {\n    width: 40px;\n    height: 40px;\n  }\n\n  .title {\n    font-size: var(--mantine-font-size-xl);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoText": `nightbot-LogoText-module__logoText-G30Cc`,
	"small": `nightbot-LogoText-module__small-cIxIz`,
	"logo": `nightbot-LogoText-module__logo-jLyzq`,
	"title": `nightbot-LogoText-module__title-fp3Cy`,
	"medium": `nightbot-LogoText-module__medium-QRkn1`,
	"large": `nightbot-LogoText-module__large-F3crf`
};
export default ___CSS_LOADER_EXPORT___;
