// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-OAuthApplicationSettings-module__redirectUri-fV5CZ {
  display: flex;
  gap: 8px;
  align-items: center;
  color: inherit;
}

.nightbot-OAuthApplicationSettings-module__invalidUri-Ry_zq {
  color: var(--color-negative);
}

.nightbot-OAuthApplicationSettings-module__actionsColumn-RwCRD,
.nightbot-OAuthApplicationSettings-module__actionsHeader-L8itd {
  width: 1px;
  text-align: right;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/OAuthApplicationSettings.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,QAAQ;EACR,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;;EAEE,UAAU;EACV,iBAAiB;AACnB","sourcesContent":[".redirectUri {\n  display: flex;\n  gap: 8px;\n  align-items: center;\n  color: inherit;\n}\n\n.invalidUri {\n  color: var(--color-negative);\n}\n\n.actionsColumn,\n.actionsHeader {\n  width: 1px;\n  text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"redirectUri": `nightbot-OAuthApplicationSettings-module__redirectUri-fV5CZ`,
	"invalidUri": `nightbot-OAuthApplicationSettings-module__invalidUri-Ry_zq`,
	"actionsColumn": `nightbot-OAuthApplicationSettings-module__actionsColumn-RwCRD`,
	"actionsHeader": `nightbot-OAuthApplicationSettings-module__actionsHeader-L8itd`
};
export default ___CSS_LOADER_EXPORT___;
