function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_with_holes(arr) {
    if (Array.isArray(arr)) return arr;
}
function _iterable_to_array_limit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _non_iterable_rest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _sliced_to_array(arr, i) {
    return _array_with_holes(arr) || _iterable_to_array_limit(arr, i) || _unsupported_iterable_to_array(arr, i) || _non_iterable_rest();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
import React, { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router';
export default function useQueryState(key, config) {
    var _useSearchParams = _sliced_to_array(useSearchParams(), 2), searchParams = _useSearchParams[0], setSearchParams = _useSearchParams[1];
    var setSearchParamsRef = React.useRef(setSearchParams);
    var _React_useState = _sliced_to_array(React.useState(function() {
        var newValue = searchParams.get(key);
        if (newValue == null) {
            return config.defaultValue;
        }
        if (config.parse == null) {
            return newValue;
        }
        return config.parse(newValue);
    }), 2), value = _React_useState[0], setValue = _React_useState[1];
    useEffect(function() {
        var newValue = searchParams.get(key);
        if (newValue == null) {
            setValue(config.defaultValue);
            return;
        }
        if (config.parse == null) {
            setValue(newValue);
            return;
        }
        setValue(config.parse(newValue));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        searchParams,
        key,
        config.parse,
        config.defaultValue
    ]);
    useEffect(function() {
        setSearchParamsRef.current = setSearchParams;
    }, [
        setSearchParams
    ]);
    var setQuery = useCallback(function(value) {
        setValue(value);
        var newParams = new URLSearchParams(window.location.search);
        if (value != null && config.serialize != null) {
            value = config.serialize(value);
        }
        if (value === config.defaultValue) {
            newParams.delete(key);
        } else {
            newParams.set(key, value);
        }
        if (newParams.toString().length === 0) {
            setSearchParamsRef.current('', {
                replace: true
            });
            return;
        }
        setSearchParamsRef.current(newParams, {
            replace: true
        });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [
        key,
        config.serialize,
        config.defaultValue
    ]);
    return [
        value,
        setQuery
    ];
}
