// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-ErrorNotice-module__errorNotice-RBh1L {
  display: flex;
  align-items: center;
  gap: 8px;
}
.nightbot-ErrorNotice-module__errorNotice-RBh1L .nightbot-ErrorNotice-module__errorNoticeIcon-BjTdy {
    color: var(--color-negative);
  }
.nightbot-ErrorNotice-module__errorNotice-RBh1L .nightbot-ErrorNotice-module__errorNoticeText-vy1P5 {
    color: var(--color-negative);
  }`, "",{"version":3,"sources":["webpack://./src/components/ErrorNotice.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AASV;AAPE;IACE,4BAA4B;EAC9B;AAEA;IACE,4BAA4B;EAC9B","sourcesContent":[".errorNotice {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n\n  .errorNoticeIcon {\n    color: var(--color-negative);\n  }\n\n  .errorNoticeText {\n    color: var(--color-negative);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorNotice": `nightbot-ErrorNotice-module__errorNotice-RBh1L`,
	"errorNoticeIcon": `nightbot-ErrorNotice-module__errorNoticeIcon-BjTdy`,
	"errorNoticeText": `nightbot-ErrorNotice-module__errorNoticeText-vy1P5`
};
export default ___CSS_LOADER_EXPORT___;
