// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-Panel-module__panel-CtSA1 {
  padding: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Panel.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf","sourcesContent":[".panel {\n  padding: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": `nightbot-Panel-module__panel-CtSA1`
};
export default ___CSS_LOADER_EXPORT___;
