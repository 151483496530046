// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-GeneralPanel-module__managerInfo-QYYoL {
  display: flex;
  gap: 8px;
  align-items: center;
  overflow: hidden;
}

.nightbot-GeneralPanel-module__managerColumn-M1htj {
  max-width: 1px;
}

.nightbot-GeneralPanel-module__actionsHeader-Q8tZk {
  width: 1px;
}

.nightbot-GeneralPanel-module__actionsColumn-dxtSS {
  text-align: right;
  width: 1px;
}

.nightbot-GeneralPanel-module__createdAtColumn-Gd1Ap {
  white-space: nowrap;
  width: 1px;
}

.nightbot-GeneralPanel-module__truncated-djdZ6 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
`, "",{"version":3,"sources":["webpack://./src/components/account_settings/GeneralPanel.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,QAAQ;EACR,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,iBAAiB;EACjB,UAAU;AACZ;;AAEA;EACE,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;AACzB","sourcesContent":[".managerInfo {\n  display: flex;\n  gap: 8px;\n  align-items: center;\n  overflow: hidden;\n}\n\n.managerColumn {\n  max-width: 1px;\n}\n\n.actionsHeader {\n  width: 1px;\n}\n\n.actionsColumn {\n  text-align: right;\n  width: 1px;\n}\n\n.createdAtColumn {\n  white-space: nowrap;\n  width: 1px;\n}\n\n.truncated {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"managerInfo": `nightbot-GeneralPanel-module__managerInfo-QYYoL`,
	"managerColumn": `nightbot-GeneralPanel-module__managerColumn-M1htj`,
	"actionsHeader": `nightbot-GeneralPanel-module__actionsHeader-Q8tZk`,
	"actionsColumn": `nightbot-GeneralPanel-module__actionsColumn-dxtSS`,
	"createdAtColumn": `nightbot-GeneralPanel-module__createdAtColumn-Gd1Ap`,
	"truncated": `nightbot-GeneralPanel-module__truncated-djdZ6`
};
export default ___CSS_LOADER_EXPORT___;
