// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-Pagination-module__pagination-Kr4kD {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  gap: 16px;
}

.nightbot-Pagination-module__pageCount-aSHw8 {
  --text-color: var(--text-secondary);
}

.nightbot-Pagination-module__actionButtons-IeSLh {
  display: flex;
  gap: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/Pagination.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;EACb,SAAS;AACX;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,aAAa;EACb,QAAQ;AACV","sourcesContent":[".pagination {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 16px;\n  gap: 16px;\n}\n\n.pageCount {\n  --text-color: var(--text-secondary);\n}\n\n.actionButtons {\n  display: flex;\n  gap: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination": `nightbot-Pagination-module__pagination-Kr4kD`,
	"pageCount": `nightbot-Pagination-module__pageCount-aSHw8`,
	"actionButtons": `nightbot-Pagination-module__actionButtons-IeSLh`
};
export default ___CSS_LOADER_EXPORT___;
