// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-TimeoutLogs-module__messageColumn-oIsmf {
  width: 100%;
  vertical-align: top;
}

.nightbot-TimeoutLogs-module__createdAtColumn-kOvYe {
  white-space: nowrap;
  vertical-align: top;
}

.nightbot-TimeoutLogs-module__userColumn-riQEw {
  white-space: nowrap;
  vertical-align: top;
  max-width: 200px;
}

.nightbot-TimeoutLogs-module__createdAtColumn-kOvYe,
.nightbot-TimeoutLogs-module__userColumn-riQEw {
  width: 1px;
}

.nightbot-TimeoutLogs-module__messageColumn-oIsmf {
  max-width: 1px;
}

.nightbot-TimeoutLogs-module__messageColumn-oIsmf .nightbot-TimeoutLogs-module__userStack-cfTXE {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
  }

.nightbot-TimeoutLogs-module__messageColumn-oIsmf .nightbot-TimeoutLogs-module__userStack-cfTXE .nightbot-TimeoutLogs-module__userName-fYDmg {
      flex: 1;
    }

.nightbot-TimeoutLogs-module__messageColumn-oIsmf .nightbot-TimeoutLogs-module__userStack-cfTXE .nightbot-TimeoutLogs-module__userTimestamp-gCDy0 {
      color: var(--text-tertiary);
      --text-color: var(--text-tertiary);
    }

.nightbot-TimeoutLogs-module__messageColumn-oIsmf .nightbot-TimeoutLogs-module__userStack-cfTXE .nightbot-TimeoutLogs-module__userMessage-qD9pI {
      width: 100%;
      color: var(--text-secondary);
      --text-color: var(--text-secondary);
    }

.nightbot-TimeoutLogs-module__reasonColumn-NMrsx,
.nightbot-TimeoutLogs-module__lengthColumn-e6tz0 {
  width: 1px;
  white-space: nowrap;
  text-align: right;
  vertical-align: top;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/TimeoutLogs.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;;EAEE,UAAU;AACZ;;AAEA;EACE,cAAc;AAuBhB;;AArBE;IACE,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,SAAS;EAgBX;;AAdE;MACE,OAAO;IACT;;AAEA;MACE,2BAA2B;MAC3B,kCAAkC;IACpC;;AAEA;MACE,WAAW;MACX,4BAA4B;MAC5B,mCAAmC;IACrC;;AAIJ;;EAEE,UAAU;EACV,mBAAmB;EACnB,iBAAiB;EACjB,mBAAmB;AACrB","sourcesContent":[".messageColumn {\n  width: 100%;\n  vertical-align: top;\n}\n\n.createdAtColumn {\n  white-space: nowrap;\n  vertical-align: top;\n}\n\n.userColumn {\n  white-space: nowrap;\n  vertical-align: top;\n  max-width: 200px;\n}\n\n.createdAtColumn,\n.userColumn {\n  width: 1px;\n}\n\n.messageColumn {\n  max-width: 1px;\n\n  .userStack {\n    display: flex;\n    align-items: center;\n    flex-wrap: wrap;\n    gap: 12px;\n\n    .userName {\n      flex: 1;\n    }\n\n    .userTimestamp {\n      color: var(--text-tertiary);\n      --text-color: var(--text-tertiary);\n    }\n\n    .userMessage {\n      width: 100%;\n      color: var(--text-secondary);\n      --text-color: var(--text-secondary);\n    }\n  }\n}\n\n.reasonColumn,\n.lengthColumn {\n  width: 1px;\n  white-space: nowrap;\n  text-align: right;\n  vertical-align: top;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messageColumn": `nightbot-TimeoutLogs-module__messageColumn-oIsmf`,
	"createdAtColumn": `nightbot-TimeoutLogs-module__createdAtColumn-kOvYe`,
	"userColumn": `nightbot-TimeoutLogs-module__userColumn-riQEw`,
	"userStack": `nightbot-TimeoutLogs-module__userStack-cfTXE`,
	"userName": `nightbot-TimeoutLogs-module__userName-fYDmg`,
	"userTimestamp": `nightbot-TimeoutLogs-module__userTimestamp-gCDy0`,
	"userMessage": `nightbot-TimeoutLogs-module__userMessage-qD9pI`,
	"reasonColumn": `nightbot-TimeoutLogs-module__reasonColumn-NMrsx`,
	"lengthColumn": `nightbot-TimeoutLogs-module__lengthColumn-e6tz0`
};
export default ___CSS_LOADER_EXPORT___;
