// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-CopyTextButton-module__copied-5MeMD {
  --tooltip-bg: var(--color-success);
}`, "",{"version":3,"sources":["webpack://./src/components/CopyTextButton.module.scss"],"names":[],"mappings":"AAAA;EACE,kCAAkC;AACpC","sourcesContent":[".copied {\n  --tooltip-bg: var(--color-success);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"copied": `nightbot-CopyTextButton-module__copied-5MeMD`
};
export default ___CSS_LOADER_EXPORT___;
