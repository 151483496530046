// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  .nightbot-badge-module__badge-EWoNW[data-variant='inverse'] {
    background-color: var(--background-tertiary);
    color: var(--text-primary);
  }

.nightbot-badge-module__label-liAIc {
  display: flex;
  gap: 8px;
  align-items: center;
  overflow: visible;
  overflow: initial;
  text-transform: none;
}
`, "",{"version":3,"sources":["webpack://./src/utils/mantine-theme/badge.module.scss"],"names":[],"mappings":";EACE;IACE,4CAA4C;IAC5C,0BAA0B;EAC5B;;AAGF;EACE,aAAa;EACb,QAAQ;EACR,mBAAmB;EACnB,iBAAe;EAAf,iBAAe;EACf,oBAAoB;AACtB","sourcesContent":[".badge {\n  &[data-variant='inverse'] {\n    background-color: var(--background-tertiary);\n    color: var(--text-primary);\n  }\n}\n\n.label {\n  display: flex;\n  gap: 8px;\n  align-items: center;\n  overflow: unset;\n  text-transform: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badge": `nightbot-badge-module__badge-EWoNW`,
	"label": `nightbot-badge-module__label-liAIc`
};
export default ___CSS_LOADER_EXPORT___;
