// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-SecurityPanel-module__stack-FzYTa {
  display: flex;
  flex-direction: column;
}

.nightbot-SecurityPanel-module__dimmedText-CGf38 {
  --text-color: var(--text-secondary);
}

@media (max-width: 768px) {
  .nightbot-SecurityPanel-module__deviceStack-DgV0p {
    text-align: right;
  }

  .nightbot-SecurityPanel-module__deviceButton-Ltg2V {
    margin: auto 0 auto auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/account_settings/SecurityPanel.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE;IACE,iBAAiB;EACnB;;EAEA;IACE,wBAAwB;EAC1B;AACF","sourcesContent":[".stack {\n  display: flex;\n  flex-direction: column;\n}\n\n.dimmedText {\n  --text-color: var(--text-secondary);\n}\n\n@media (max-width: $mantine-breakpoint-sm) {\n  .deviceStack {\n    text-align: right;\n  }\n\n  .deviceButton {\n    margin: auto 0 auto auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stack": `nightbot-SecurityPanel-module__stack-FzYTa`,
	"dimmedText": `nightbot-SecurityPanel-module__dimmedText-CGf38`,
	"deviceStack": `nightbot-SecurityPanel-module__deviceStack-DgV0p`,
	"deviceButton": `nightbot-SecurityPanel-module__deviceButton-Ltg2V`
};
export default ___CSS_LOADER_EXPORT___;
