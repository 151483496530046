export var SortDirections;
(function(SortDirections) {
    SortDirections["ASC"] = "asc";
    SortDirections["DESC"] = "desc";
})(SortDirections || (SortDirections = {}));
export var LordIconSizes;
(function(LordIconSizes) {
    LordIconSizes[LordIconSizes["SMALL"] = 22] = "SMALL";
    LordIconSizes[LordIconSizes["MEDIUM"] = 32] = "MEDIUM";
    LordIconSizes[LordIconSizes["LARGE"] = 48] = "LARGE";
})(LordIconSizes || (LordIconSizes = {}));
export var GiveawayType;
(function(GiveawayType) {
    GiveawayType["ACTIVE_USER"] = "active_user";
    GiveawayType["KEYWORD"] = "keyword";
    GiveawayType["RANDOM_NUMBER"] = "random_number";
})(GiveawayType || (GiveawayType = {}));
export var GiveawayState;
(function(GiveawayState) {
    GiveawayState["EDIT"] = "edit";
    GiveawayState["ACTIVE"] = "active";
    GiveawayState["FINISHED"] = "finished";
})(GiveawayState || (GiveawayState = {}));
export var GiveawaySteps;
(function(GiveawaySteps) {
    GiveawaySteps["TYPE"] = "type";
    GiveawaySteps["ELIGIBILITY"] = "eligibility";
    GiveawaySteps["CONDITIONS"] = "conditions";
})(GiveawaySteps || (GiveawaySteps = {}));
export var PopupEventNames;
(function(PopupEventNames) {
    PopupEventNames["RESULT_QUERYSTRING_UPDATE"] = "resultQuerystringUpdate";
    PopupEventNames["CLOSE"] = "close";
})(PopupEventNames || (PopupEventNames = {}));
export var GiveawayUserTags;
(function(GiveawayUserTags) {
    GiveawayUserTags[GiveawayUserTags["WINNER"] = 0] = "WINNER";
    GiveawayUserTags[GiveawayUserTags["MANUAL_REMOVAL"] = 1] = "MANUAL_REMOVAL";
    GiveawayUserTags[GiveawayUserTags["KEYWORD_SPAM"] = 2] = "KEYWORD_SPAM";
    GiveawayUserTags[GiveawayUserTags["WRONG_USER_LEVEL"] = 3] = "WRONG_USER_LEVEL";
    GiveawayUserTags[GiveawayUserTags["NOT_ACTIVE"] = 4] = "NOT_ACTIVE";
})(GiveawayUserTags || (GiveawayUserTags = {}));
export var SongTableType;
(function(SongTableType) {
    SongTableType["QUEUE"] = "queue";
    SongTableType["PLAYLIST"] = "playlist";
})(SongTableType || (SongTableType = {}));
export var PlaylistSortType;
(function(PlaylistSortType) {
    PlaylistSortType["CREATED_AT"] = "createdAt";
    PlaylistSortType["TITLE"] = "title";
    PlaylistSortType["RANDOM"] = "random";
})(PlaylistSortType || (PlaylistSortType = {}));
export var SettingPanels;
(function(SettingPanels) {
    SettingPanels["GENERAL"] = "general";
    SettingPanels["SECURITY"] = "security";
    SettingPanels["CONNECTIONS"] = "connections";
})(SettingPanels || (SettingPanels = {}));
