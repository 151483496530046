// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-Graph-module__graphIndicator-ic53Y {
  position: absolute;
  pointer-events: none;
  z-index: 10;
}

.nightbot-Graph-module__translate-E3yp4 {
  transform: translate(-50%, -50%);
}

.nightbot-Graph-module__glyphContainer-FPEMV {
  overflow: visible;
  position: relative;
}

.nightbot-Graph-module__glyphContainer-FPEMV .nightbot-Graph-module__circle-USPK_ {
    position: absolute;
    width: 100%;
    height: 100%;
    fill-opacity: 0.5;
  }

.nightbot-Graph-module__glyphContainer-FPEMV .nightbot-Graph-module__smallCircle-XGqv1 {
    position: absolute;
    width: 50%;
    height: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    fill: white;
  }

.nightbot-Graph-module__glyph-zLd5J {
  width: 20px;
  height: 20px;
}

.nightbot-Graph-module__graph-pMljE {
  position: relative;
}
`, "",{"version":3,"sources":["webpack://./src/components/Graph.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,WAAW;AACb;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AAkBpB;;AAhBE;IACE,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,iBAAiB;EACnB;;AAEA;IACE,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,gCAAgC;IAChC,QAAQ;IACR,SAAS;IACT,WAAW;EACb;;AAGF;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".graphIndicator {\n  position: absolute;\n  pointer-events: none;\n  z-index: 10;\n}\n\n.translate {\n  transform: translate(-50%, -50%);\n}\n\n.glyphContainer {\n  overflow: visible;\n  position: relative;\n\n  .circle {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    fill-opacity: 0.5;\n  }\n\n  .smallCircle {\n    position: absolute;\n    width: 50%;\n    height: 50%;\n    transform: translate(-50%, -50%);\n    top: 50%;\n    left: 50%;\n    fill: white;\n  }\n}\n\n.glyph {\n  width: 20px;\n  height: 20px;\n}\n\n.graph {\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"graphIndicator": `nightbot-Graph-module__graphIndicator-ic53Y`,
	"translate": `nightbot-Graph-module__translate-E3yp4`,
	"glyphContainer": `nightbot-Graph-module__glyphContainer-FPEMV`,
	"circle": `nightbot-Graph-module__circle-USPK_`,
	"smallCircle": `nightbot-Graph-module__smallCircle-XGqv1`,
	"glyph": `nightbot-Graph-module__glyph-zLd5J`,
	"graph": `nightbot-Graph-module__graph-pMljE`
};
export default ___CSS_LOADER_EXPORT___;
