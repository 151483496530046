// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-Heading-module__heading-m9fMa {
  display: flex;
  flex-direction: column;
}
.nightbot-Heading-module__heading-m9fMa .nightbot-Heading-module__subHeading-L1ZeK {
    color: var(--text-secondary);
  }
`, "",{"version":3,"sources":["webpack://./src/components/Heading.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AAKxB;AAHE;IACE,4BAA4B;EAC9B","sourcesContent":[".heading {\n  display: flex;\n  flex-direction: column;\n\n  .subHeading {\n    color: var(--text-secondary);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": `nightbot-Heading-module__heading-m9fMa`,
	"subHeading": `nightbot-Heading-module__subHeading-L1ZeK`
};
export default ___CSS_LOADER_EXPORT___;
