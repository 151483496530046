// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-CommandsDefault-module__commandEnabledColumn-GcWXs {
  text-align: center;
}

.nightbot-CommandsDefault-module__commandMessageColumn-isLhx {
  width: 100%;
}

.nightbot-CommandsDefault-module__commandUserLevelColumn-WSCz0 {
  text-align: right;
}

.nightbot-CommandsDefault-module__commandNameStack-xxPjt {
  display: flex;
  align-items: center;
  gap: 8px;
}

.nightbot-CommandsDefault-module__commandActionsColumn-TtrTz {
  width: 1px;
}

.nightbot-CommandsDefault-module__commandActionsColumn-TtrTz .nightbot-CommandsDefault-module__actionIcons-wEv_O {
    display: flex;
    gap: 8px;
    justify-content: right;
  }

.nightbot-CommandsDefault-module__commandEnabledSwitchContainer-w6Uz2 {
  display: flex;
  justify-content: center;
}

.nightbot-CommandsDefault-module__commandEnabledBadge-G0F4W {
  margin-left: 4px;
}

.nightbot-CommandsDefault-module__commandDescription-nZGBB {
  --text-color: var(--text-secondary);
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/CommandsDefault.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,UAAU;AAOZ;;AALE;IACE,aAAa;IACb,QAAQ;IACR,sBAAsB;EACxB;;AAGF;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mCAAmC;EACnC,WAAW;AACb","sourcesContent":[".commandEnabledColumn {\n  text-align: center;\n}\n\n.commandMessageColumn {\n  width: 100%;\n}\n\n.commandUserLevelColumn {\n  text-align: right;\n}\n\n.commandNameStack {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n.commandActionsColumn {\n  width: 1px;\n\n  .actionIcons {\n    display: flex;\n    gap: 8px;\n    justify-content: right;\n  }\n}\n\n.commandEnabledSwitchContainer {\n  display: flex;\n  justify-content: center;\n}\n\n.commandEnabledBadge {\n  margin-left: 4px;\n}\n\n.commandDescription {\n  --text-color: var(--text-secondary);\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"commandEnabledColumn": `nightbot-CommandsDefault-module__commandEnabledColumn-GcWXs`,
	"commandMessageColumn": `nightbot-CommandsDefault-module__commandMessageColumn-isLhx`,
	"commandUserLevelColumn": `nightbot-CommandsDefault-module__commandUserLevelColumn-WSCz0`,
	"commandNameStack": `nightbot-CommandsDefault-module__commandNameStack-xxPjt`,
	"commandActionsColumn": `nightbot-CommandsDefault-module__commandActionsColumn-TtrTz`,
	"actionIcons": `nightbot-CommandsDefault-module__actionIcons-wEv_O`,
	"commandEnabledSwitchContainer": `nightbot-CommandsDefault-module__commandEnabledSwitchContainer-w6Uz2`,
	"commandEnabledBadge": `nightbot-CommandsDefault-module__commandEnabledBadge-G0F4W`,
	"commandDescription": `nightbot-CommandsDefault-module__commandDescription-nZGBB`
};
export default ___CSS_LOADER_EXPORT___;
