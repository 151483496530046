// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-CommandsCustom-module__commandName-Uq1dq {
  min-width: 100px;
}

.nightbot-CommandsCustom-module__commandStack-mMJw9 {
  display: flex;
  flex-direction: column;
  gap: 4px;

  word-break: break-all;
}

.nightbot-CommandsCustom-module__commandStack-mMJw9 .nightbot-CommandsCustom-module__commandAliasResponse-dWKL7 {
    color: var(--text-secondary);
  }

.nightbot-CommandsCustom-module__commandMessageColumn-zPc1h {
  word-break: break-all;
  width: 100%;
}

.nightbot-CommandsCustom-module__commandUserLevelColumn-cpMZk {
  text-align: right;
}

.nightbot-CommandsCustom-module__actionIcons-x8Lva {
  display: flex;
  gap: 8px;
  justify-content: right;
}

@media (min-width: 768px) {

.nightbot-CommandsCustom-module__commandAliasResponse-dWKL7 {
    display: none;
}
  }
`, "",{"version":3,"sources":["webpack://./src/components/pages/CommandsCustom.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;;EAER,qBAAqB;AAKvB;;AAHE;IACE,4BAA4B;EAC9B;;AAGF;EACE,qBAAqB;EACrB,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,QAAQ;EACR,sBAAsB;AACxB;;AAGE;;AADF;IAEI,aAAa;AAEjB;EADE","sourcesContent":[".commandName {\n  min-width: 100px;\n}\n\n.commandStack {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n\n  word-break: break-all;\n\n  .commandAliasResponse {\n    color: var(--text-secondary);\n  }\n}\n\n.commandMessageColumn {\n  word-break: break-all;\n  width: 100%;\n}\n\n.commandUserLevelColumn {\n  text-align: right;\n}\n\n.actionIcons {\n  display: flex;\n  gap: 8px;\n  justify-content: right;\n}\n\n.commandAliasResponse {\n  @media (min-width: $mantine-breakpoint-sm) {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"commandName": `nightbot-CommandsCustom-module__commandName-Uq1dq`,
	"commandStack": `nightbot-CommandsCustom-module__commandStack-mMJw9`,
	"commandAliasResponse": `nightbot-CommandsCustom-module__commandAliasResponse-dWKL7`,
	"commandMessageColumn": `nightbot-CommandsCustom-module__commandMessageColumn-zPc1h`,
	"commandUserLevelColumn": `nightbot-CommandsCustom-module__commandUserLevelColumn-cpMZk`,
	"actionIcons": `nightbot-CommandsCustom-module__actionIcons-x8Lva`
};
export default ___CSS_LOADER_EXPORT___;
