function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
import React from 'react';
import isDeepEqual from 'fast-deep-equal';
import { useDebouncedCallback } from 'use-debounce';
export default function useDebouncedCallbackOnChange(formik, callback) {
    var delay = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 1000;
    var callbackRef = React.useRef(callback);
    var oldValuesRef = React.useRef(formik.initialValues);
    var debouncedValidate = useDebouncedCallback(function(values) {
        oldValuesRef.current = _object_spread({}, values);
        callbackRef.current();
    }, delay);
    React.useEffect(function() {
        callbackRef.current = callback;
    }, [
        callback
    ]);
    React.useEffect(function() {
        if (debouncedValidate.isPending() && isDeepEqual(oldValuesRef.current, formik.values)) {
            debouncedValidate.cancel();
            return;
        }
        debouncedValidate(formik.values);
    }, [
        debouncedValidate,
        formik.values
    ]);
}
