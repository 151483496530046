// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-avatar-module__avatar-ACIQQ {
  --avatar-bg: var(--background-secondary);
}
`, "",{"version":3,"sources":["webpack://./src/utils/mantine-theme/avatar.module.scss"],"names":[],"mappings":"AAAA;EACE,wCAAwC;AAC1C","sourcesContent":[".avatar {\n  --avatar-bg: var(--background-secondary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar": `nightbot-avatar-module__avatar-ACIQQ`
};
export default ___CSS_LOADER_EXPORT___;
