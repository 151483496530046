// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.nightbot-NavItem-module__navItem-UTLoT {
  --button-radius: var(--mantine-radius-md);
  --button-justify: left;
  --button-color: var(--text-secondary);
}
.nightbot-NavItem-module__navItem-UTLoT .nightbot-NavItem-module__navItemInner-TzJzI {
    color: var(--text-secondary);
  }
`, "",{"version":3,"sources":["webpack://./src/components/NavItem.module.scss"],"names":[],"mappings":";;AAEA;EACE,yCAAyC;EACzC,sBAAsB;EACtB,qCAAqC;AAKvC;AAHE;IACE,4BAA4B;EAC9B","sourcesContent":["\n\n.navItem {\n  --button-radius: var(--mantine-radius-md);\n  --button-justify: left;\n  --button-color: var(--text-secondary);\n\n  .navItemInner {\n    color: var(--text-secondary);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navItem": `nightbot-NavItem-module__navItem-UTLoT`,
	"navItemInner": `nightbot-NavItem-module__navItemInner-TzJzI`
};
export default ___CSS_LOADER_EXPORT___;
