// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-OnboardingModal-module__checkIcon-u0V53 {
  color: var(--color-success);
}

.nightbot-OnboardingModal-module__modalDescription-fExLK {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/OnboardingModal.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX","sourcesContent":[".checkIcon {\n  color: var(--color-success);\n}\n\n.modalDescription {\n  padding: 20px;\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkIcon": `nightbot-OnboardingModal-module__checkIcon-u0V53`,
	"modalDescription": `nightbot-OnboardingModal-module__modalDescription-fExLK`
};
export default ___CSS_LOADER_EXPORT___;
