// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-TwoFactorSetupModal-module__disclaimer-wDt7S {
  --text-color: var(--text-secondary);
  color: var(--text-secondary);
}

.nightbot-TwoFactorSetupModal-module__modalDescription-e2RqJ {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px;
}

.nightbot-TwoFactorSetupModal-module__qrCode-t07Jc > path:first-child {
  fill: var(--background-primary);
}

.nightbot-TwoFactorSetupModal-module__qrCode-t07Jc > path:last-child {
  fill: var(--text-primary);
}


`, "",{"version":3,"sources":["webpack://./src/components/twofactor/TwoFactorSetupModal.module.scss"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,4BAA4B;AAC9B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;EACT,aAAa;AACf;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".disclaimer {\n  --text-color: var(--text-secondary);\n  color: var(--text-secondary);\n}\n\n.modalDescription {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 16px;\n  padding: 16px;\n}\n\n.qrCode > path:first-child {\n  fill: var(--background-primary);\n}\n\n.qrCode > path:last-child {\n  fill: var(--text-primary);\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"disclaimer": `nightbot-TwoFactorSetupModal-module__disclaimer-wDt7S`,
	"modalDescription": `nightbot-TwoFactorSetupModal-module__modalDescription-e2RqJ`,
	"qrCode": `nightbot-TwoFactorSetupModal-module__qrCode-t07Jc`
};
export default ___CSS_LOADER_EXPORT___;
