import React from 'react';
import { WebRoutes } from '@/utils/constants';
import { sanitizePathname } from '@/utils/url';
import { createSearchParams, useLocation, useNavigate } from 'react-router';
export default function useLoginRedirect(redirect) {
    var navigate = useNavigate();
    var _useLocation = useLocation(), pathname = _useLocation.pathname, search = _useLocation.search;
    return React.useCallback(function() {
        var path = "".concat(pathname).concat(search);
        var sanitizedPath = sanitizePathname(redirect !== null && redirect !== void 0 ? redirect : path);
        if (sanitizedPath == null) {
            navigate(WebRoutes.LOGIN);
            return;
        }
        navigate({
            pathname: WebRoutes.LOGIN,
            search: createSearchParams({
                return_to: sanitizedPath
            }).toString()
        });
    }, [
        navigate,
        pathname,
        redirect,
        search
    ]);
}
