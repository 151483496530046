// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-TableHeading-module__tableHeading-oWHjI {
  display: flex;
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  color: var(--text-secondary);
}

.nightbot-TableHeading-module__tableHeading-oWHjI .nightbot-TableHeading-module__icon-jp2Vg {
    position: absolute;
    right: -16px;
  }

.nightbot-TableHeading-module__tableHeading-oWHjI .nightbot-TableHeading-module__heading-NkeUt {
    font-weight: bold;
    text-transform: uppercase;
  }

.nightbot-TableHeading-module__tableHeadingButton-wJbtg {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.nightbot-TableHeading-module__alignRight-YM7gK {
  margin: auto 0 auto auto;
}

.nightbot-TableHeading-module__alignRight-YM7gK .nightbot-TableHeading-module__icon-jp2Vg {
    right: 0;
    left: -16px;
  }
`, "",{"version":3,"sources":["webpack://./src/components/TableHeading.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,uBAAkB;EAAlB,kBAAkB;EAClB,mBAAmB;EACnB,4BAA4B;AAW9B;;AATE;IACE,kBAAkB;IAClB,YAAY;EACd;;AAEA;IACE,iBAAiB;IACjB,yBAAyB;EAC3B;;AAGF;EACE,eAAe;EACf,yBAAiB;KAAjB,sBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,wBAAwB;AAM1B;;AAJE;IACE,QAAQ;IACR,WAAW;EACb","sourcesContent":[".tableHeading {\n  display: flex;\n  position: relative;\n  width: fit-content;\n  align-items: center;\n  color: var(--text-secondary);\n\n  .icon {\n    position: absolute;\n    right: -16px;\n  }\n\n  .heading {\n    font-weight: bold;\n    text-transform: uppercase;\n  }\n}\n\n.tableHeadingButton {\n  cursor: pointer;\n  user-select: none;\n}\n\n.alignRight {\n  margin: auto 0 auto auto;\n\n  .icon {\n    right: 0;\n    left: -16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableHeading": `nightbot-TableHeading-module__tableHeading-oWHjI`,
	"icon": `nightbot-TableHeading-module__icon-jp2Vg`,
	"heading": `nightbot-TableHeading-module__heading-NkeUt`,
	"tableHeadingButton": `nightbot-TableHeading-module__tableHeadingButton-wJbtg`,
	"alignRight": `nightbot-TableHeading-module__alignRight-YM7gK`
};
export default ___CSS_LOADER_EXPORT___;
