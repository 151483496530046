// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-AliasResponse-module__aliasResponse-tvi4Q {
  white-space: normal;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.nightbot-AliasResponse-module__aliasResponse-tvi4Q .nightbot-AliasResponse-module__tooltip-m5DhV {
    display: inline-flex;
  }

.nightbot-AliasResponse-module__label-GIWhj {
  text-transform: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/AliasResponse.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,QAAQ;EACR,eAAe;AAKjB;;AAHE;IACE,oBAAoB;EACtB;;AAGF;EACE,oBAAoB;AACtB","sourcesContent":[".aliasResponse {\n  white-space: normal;\n  display: flex;\n  gap: 8px;\n  flex-wrap: wrap;\n\n  .tooltip {\n    display: inline-flex;\n  }\n}\n\n.label {\n  text-transform: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aliasResponse": `nightbot-AliasResponse-module__aliasResponse-tvi4Q`,
	"tooltip": `nightbot-AliasResponse-module__tooltip-m5DhV`,
	"label": `nightbot-AliasResponse-module__label-GIWhj`
};
export default ___CSS_LOADER_EXPORT___;
