// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-InputModal-module__inputModalDescription-_aWQh {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}
.nightbot-InputModal-module__inputModalDescription-_aWQh .nightbot-InputModal-module__inputModalDescriptionText-jblVy {
    color: var(--text-secondary);
  }
.nightbot-InputModal-module__inputModalDescription-_aWQh .nightbot-InputModal-module__input-uKvB4 {
    background-color: var(--background-secondary)
  }`, "",{"version":3,"sources":["webpack://./src/components/InputModal.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,aAAa;AASf;AAPE;IACE,4BAA4B;EAC9B;AAEA;IACE;EACF","sourcesContent":[".inputModalDescription {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  padding: 20px;\n\n  .inputModalDescriptionText {\n    color: var(--text-secondary);\n  }\n\n  .input {\n    background-color: var(--background-secondary)\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputModalDescription": `nightbot-InputModal-module__inputModalDescription-_aWQh`,
	"inputModalDescriptionText": `nightbot-InputModal-module__inputModalDescriptionText-jblVy`,
	"input": `nightbot-InputModal-module__input-uKvB4`
};
export default ___CSS_LOADER_EXPORT___;
