// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./ScrollShadows.module.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-UserMenu-module__channelStack-QQe9z {
  display: flex;
  flex-direction: column;
  max-height: 132px;
  overflow-y: scroll;
  padding-top: 4px;
  padding-bottom: 4px;
}

.nightbot-UserMenu-module__itemLabel-PaLGG {
  min-width: 0px;
}`, "",{"version":3,"sources":["webpack://./src/components/UserMenu.module.scss"],"names":[],"mappings":"AAAA;EAEE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".channelStack {\n  composes: scrollShadows from './ScrollShadows.module.scss';\n  display: flex;\n  flex-direction: column;\n  max-height: 132px;\n  overflow-y: scroll;\n  padding-top: 4px;\n  padding-bottom: 4px;\n}\n\n.itemLabel {\n  min-width: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"channelStack": `nightbot-UserMenu-module__channelStack-QQe9z ${___CSS_LOADER_ICSS_IMPORT_0___.locals["scrollShadows"]}`,
	"itemLabel": `nightbot-UserMenu-module__itemLabel-PaLGG`
};
export default ___CSS_LOADER_EXPORT___;
