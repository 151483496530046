// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-Dashboard-module__dashboardHeader-ZHlA1 {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  align-items: flex-end;
}
@media (max-width: 768px) {
  .nightbot-Dashboard-module__dashboardHeader-ZHlA1 {
    flex-direction: column;
    align-items: flex-start;
}
  }
`, "",{"version":3,"sources":["webpack://./src/components/pages/Dashboard.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,SAAS;EACT,qBAAqB;AAMvB;AAJE;EANF;IAOI,sBAAsB;IACtB,uBAAuB;AAE3B;EADE","sourcesContent":[".dashboardHeader {\n  display: flex;\n  justify-content: space-between;\n  gap: 24px;\n  align-items: flex-end;\n\n  @media (max-width: $mantine-breakpoint-sm) {\n    flex-direction: column;\n    align-items: flex-start;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboardHeader": `nightbot-Dashboard-module__dashboardHeader-ZHlA1`
};
export default ___CSS_LOADER_EXPORT___;
