// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./ScrollShadows.module.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-SideBar-module__sideBar-tg4Ot {

  display: flex;
  flex-direction: column;
  padding: 16px 16px 0 16px;
  gap: 16px;
  height: 100%;
  overflow-y: scroll;
  border-right-width: 1px;
  border-right-color: var(--border-primary);
}
.nightbot-SideBar-module__sideBar-tg4Ot .nightbot-SideBar-module__logoText-fMvFN {
    margin-bottom: 16px;
    padding: 12px;
  }
.nightbot-SideBar-module__sideBar-tg4Ot .nightbot-SideBar-module__navItemsBottom-QcLYI,.nightbot-SideBar-module__sideBar-tg4Ot .nightbot-SideBar-module__navItemsTop-Dws4F {
    display: flex;
    flex-direction: column;
  }
.nightbot-SideBar-module__sideBar-tg4Ot .nightbot-SideBar-module__navItemsBottom-QcLYI {
    margin-top: auto;
  }
.nightbot-SideBar-module__sideBar-tg4Ot .nightbot-SideBar-module__navItems-hMJX3 {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
.nightbot-SideBar-module__sideBar-tg4Ot .nightbot-SideBar-module__userMenuButton-dJREH {
    position: sticky;
    bottom: 0;
    padding-bottom: 16px;
    background-color: var(--background-primary);
  }

`, "",{"version":3,"sources":["webpack://./src/components/SideBar.module.scss"],"names":[],"mappings":"AAAA;;EAGE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,SAAS;EACT,YAAY;EACZ,kBAAkB;EAClB,uBAAuB;EACvB,yCAAyC;AA4B3C;AA1BE;IACE,mBAAmB;IACnB,aAAa;EACf;AAEA;IACE,aAAa;IACb,sBAAsB;EACxB;AAEA;IACE,gBAAgB;EAClB;AAEA;IACE,OAAO;IACP,aAAa;IACb,sBAAsB;EACxB;AAEA;IACE,gBAAgB;IAChB,SAAS;IACT,oBAAoB;IACpB,2CAA2C;EAC7C","sourcesContent":[".sideBar {\n  composes: scrollShadows from './ScrollShadows.module.scss';\n\n  display: flex;\n  flex-direction: column;\n  padding: 16px 16px 0 16px;\n  gap: 16px;\n  height: 100%;\n  overflow-y: scroll;\n  border-right-width: 1px;\n  border-right-color: var(--border-primary);\n\n  .logoText {\n    margin-bottom: 16px;\n    padding: 12px;\n  }\n\n  .navItemsBottom, .navItemsTop {\n    display: flex;\n    flex-direction: column;\n  }\n\n  .navItemsBottom {\n    margin-top: auto;\n  }\n\n  .navItems {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n  }\n\n  .userMenuButton {\n    position: sticky;\n    bottom: 0;\n    padding-bottom: 16px;\n    background-color: var(--background-primary);\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sideBar": `nightbot-SideBar-module__sideBar-tg4Ot ${___CSS_LOADER_ICSS_IMPORT_0___.locals["scrollShadows"]}`,
	"logoText": `nightbot-SideBar-module__logoText-fMvFN`,
	"navItemsBottom": `nightbot-SideBar-module__navItemsBottom-QcLYI`,
	"navItemsTop": `nightbot-SideBar-module__navItemsTop-Dws4F`,
	"navItems": `nightbot-SideBar-module__navItems-hMJX3`,
	"userMenuButton": `nightbot-SideBar-module__userMenuButton-dJREH`
};
export default ___CSS_LOADER_EXPORT___;
