// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-AuthProviderSteps-module__disclaimer-QkzUq {
  --text-color: var(--text-secondary);
  color: var(--text-secondary);
  margin-bottom: 16px;
}

.nightbot-AuthProviderSteps-module__footer-Puyok {
  --text-color: var(--text-secondary);
  color: var(--text-secondary);
  margin-top: 16px;
}

.nightbot-AuthProviderSteps-module__imageContainer-yzxO2 {
  border-radius: var(--mantine-radius-lg);
  overflow: hidden;
  border-width: 1px;
  border-color: var(--border-secondary);
  margin-top: 16px;
  margin-bottom: 16px;
}

.nightbot-AuthProviderSteps-module__copyButton-foKuk {
  max-width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/AuthProviderSteps.module.scss"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,4BAA4B;EAC5B,mBAAmB;AACrB;;AAEA;EACE,mCAAmC;EACnC,4BAA4B;EAC5B,gBAAgB;AAClB;;AAEA;EACE,uCAAuC;EACvC,gBAAgB;EAChB,iBAAiB;EACjB,qCAAqC;EACrC,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".disclaimer {\n  --text-color: var(--text-secondary);\n  color: var(--text-secondary);\n  margin-bottom: 16px;\n}\n\n.footer {\n  --text-color: var(--text-secondary);\n  color: var(--text-secondary);\n  margin-top: 16px;\n}\n\n.imageContainer {\n  border-radius: var(--mantine-radius-lg);\n  overflow: hidden;\n  border-width: 1px;\n  border-color: var(--border-secondary);\n  margin-top: 16px;\n  margin-bottom: 16px;\n}\n\n.copyButton {\n  max-width: 100%;\n  margin-top: 16px;\n  margin-bottom: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"disclaimer": `nightbot-AuthProviderSteps-module__disclaimer-QkzUq`,
	"footer": `nightbot-AuthProviderSteps-module__footer-Puyok`,
	"imageContainer": `nightbot-AuthProviderSteps-module__imageContainer-yzxO2`,
	"copyButton": `nightbot-AuthProviderSteps-module__copyButton-foKuk`
};
export default ___CSS_LOADER_EXPORT___;
