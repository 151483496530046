// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-Modal-module__modalActions-RqaJW {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.nightbot-Modal-module__modalTitle-LGP7N {
  display: flex;
  gap: 12px;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Modal.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,aAAa;AACf;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB","sourcesContent":[".modalActions {\n  display: flex;\n  justify-content: space-between;\n  padding: 20px;\n}\n\n.modalTitle {\n  display: flex;\n  gap: 12px;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalActions": `nightbot-Modal-module__modalActions-RqaJW`,
	"modalTitle": `nightbot-Modal-module__modalTitle-LGP7N`
};
export default ___CSS_LOADER_EXPORT___;
