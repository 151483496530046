// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-FeatureSection-module__brandLogo-oKRci {
  flex: 1;
}

.nightbot-FeatureSection-module__brandLogoContainer-WIDMB {
  margin-right: 32px;
  margin-left: 32px;
  flex: 1;
  align-self: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/landing/FeatureSection.module.css"],"names":[],"mappings":"AAAA;EACE,OAAO;AACT;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,OAAO;EACP,kBAAkB;AACpB","sourcesContent":[".brandLogo {\n  flex: 1;\n}\n\n.brandLogoContainer {\n  margin-right: 32px;\n  margin-left: 32px;\n  flex: 1;\n  align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brandLogo": `nightbot-FeatureSection-module__brandLogo-oKRci`,
	"brandLogoContainer": `nightbot-FeatureSection-module__brandLogoContainer-WIDMB`
};
export default ___CSS_LOADER_EXPORT___;
