// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nightbot-AdvancedRevealButton-module__advancedButtonInner-nruvq {
  color: var(--text-secondary);
  width: -moz-fit-content;
  width: fit-content;
}
`, "",{"version":3,"sources":["webpack://./src/components/AdvancedRevealButton.module.scss"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,uBAAkB;EAAlB,kBAAkB;AACpB","sourcesContent":[".advancedButtonInner {\n  color: var(--text-secondary);\n  width: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"advancedButtonInner": `nightbot-AdvancedRevealButton-module__advancedButtonInner-nruvq`
};
export default ___CSS_LOADER_EXPORT___;
